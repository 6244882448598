<template>
  <div id="sample-schedules">
    <h3>Sample Schedules</h3>
    <p>
      This is a list of sample schedules for your water system. The dates given are compliance period dates. You must take the number of samples indicated by <b>Samples Required</b> by the compliance period <b>End Date</b>. A schedule row will be <span class="bg-warning">highlighted yellow</span> if it is within 3 months of the compliance period <b>End Date</b> and <b>Samples Taken</b> is less than <b>Samples Required</b>.
    </p>
    <b-table striped hover :items="sample_schedules" :fields="table_fields" :tbody-tr-class="rowClass"></b-table>
  </div>
</template>
<script>
export default {
  name: "SampleSchedules",
  data() {
    return {
      sample_schedules: [],
      table_fields: [
        { key: "schedule_name", label: "Schedule Name" },
        { key: "facility_info", label: "Facility" },
        { key: "sub_smppt_info", label: "Subschedule Sampling Point" },
        { key: "samples_required", label: "Samples Required" },
        { key: "samples_taken", label: "Samples Taken" },
        { key: "mprd_name", label: "Monitoring Period" },
        { key: "cp_prd_begin_dt", label: "Begin Date", formatter: this.formatDate },
        { key: "cp_prd_end_dt", label: "End Date", formatter: this.formatDate },
      ]
    }
  },
  computed: {
    pws_id: function() {
      return this.$store.state.pws_id
    },
  },
  created() {
    console.log("created")
    this.fetchSchedules()
  },
  mounted() {
    console.log("mounted")
    this.app_mounted = true
  },
  methods: {
    formatDate(dtstr) {
      if (dtstr) {
        return new Date(dtstr).toLocaleDateString('en-US', { timeZone: 'America/Chicago' })
      } else {
        return ""
      }
    },
    rowClass(item) {
      // date 3 months in the future
      const future = new Date()
      future.setMonth(future.getMonth() + 6)
      if (item.cp_prd_end_dt && new Date(item.cp_prd_end_dt) < future && item.samples_taken < item.samples_required) {
        return "table-warning"
      } else {
        return ""
      }
    },
    fetchSchedules() {
      console.log("called schedules")
      const path = process.env.VUE_APP_API_URI + "/pws-sample-schedules"
      return this.axios
        .get(path, { params: { pws_id: this.$store.state.pws_id } })
        .then((res) => {
          if (res.data.sample_schedules) {
            for (const schedule of res.data.sample_schedules) {
              if (schedule.analyte_name) {
                schedule.schedule_name = schedule.analyte_name
              } else {
                schedule.schedule_name = schedule.analyte_group
              }
              if (schedule.facility_local_name.length > 0) {
                schedule.facility_info = schedule.facility_id + " - " + schedule.facility_local_name
              } else {
                schedule.facility_info = schedule.facility_id
              }
              // subschedule
              if (schedule.sub_smppt_id) {
                if (schedule.sub_smppt_desc) {
                  schedule.sub_smppt_info = schedule.sub_smppt_id + " - " + schedule.sub_smppt_desc
                } else {
                  schedule.sub_smppt_info = schedule.sub_smppt_id
                }
              }
              this.sample_schedules.push(schedule)
            }
          } else {
            console.log("sample schedule data fetch failed")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  }
}

</script>
