<template>
  <div class="rtcr-site-plan">
    <h3 class="mt-4 mb-2">
      Routine TCR Sample Sites (Option {{ option_number }})
    </h3>

    <p class="mt-2 mb-4">
      {{ this.pws_id }} {{ this.pws_name }}

      <span v-if="this.amend_plan_id">
        -
        <em
          ><span class="bg-warning"
            >Amending Plan Submission #{{ this.amend_plan_id }}</span
          ></em
        ></span
      ><br />
      Additional Instructions:
      <a
        href="/static/docs/2021-25-RTCRSampleSitePlanOption1-LETTER.pdf"
        target="_blank"
        >MSDH Letter</a
      >
      and
      <a
        href="/static/docs/2021-25-RTCRSampleSitePlanOption1-OTHER.pdf"
        target="_blank"
        >Guidance</a
      >
    </p>
    <b-button variant="primary" @click="showMap" class="mb-3"
      >View Map</b-button
    >
    <!--
    <b-button variant="primary" v-b-modal.print-modal class="ml-2 mb-3"
      >Print View</b-button
    >
    -->
    <b-button variant="primary" v-b-modal.comments-modal class="ml-2 mb-3"
      >Add Comments</b-button
    >
    <b-button
      variant="primary"
      :disabled="!all_sp_mapped"
      @click="onShowSigningModal"
      class="ml-2 mb-3"
      >Sign and Send</b-button
    >

    <div>
      <b-alert variant="danger" :show="!all_sp_mapped">
        Some sample points are not mapped as indicated by the red 'Not Set'
        button. Options are: 1. Correct the address. 2. Choose a point on the
        map. 3. Inactivate the sample point if it is not used.
      </b-alert>
      <div>
        <b-button variant="primary" v-b-modal.modal-table-help
          >Sample Point Table Instructions</b-button
        >
        <b-modal
          ok-only
          id="modal-table-help"
          title="Sample Point Table Instructions"
        >
          <p>
            This table should include every TCR sample point on your water
            system along with its location. If the location cannot be determined
            from the address or that location is inaccurate, choose the location
            on the map using the button in the Mapping column. If the address
            needs to be corrected (not changed), use the green button in the ID
            column to correct it. If there is additional information you would
            like to convey, use the 'Add Comments' button.
            <br /><br />
            Choose a specific point on the map when
          </p>
          <ol>
            <li>The address does not map.</li>
            <li>The address is not specific enough.</li>
          </ol>
          <p>
            New Sample Points can be added using the button below the table.
            <br /><br />
            Sample Points no longer in use can be inactivated using the green
            button in the ID column.
            <br /><br />
            When your sample point list is complete, click 'Sign and Send',
            enter your name, then click the second 'Sign and Send' button. You
            should see a green confirmation message. If you have any issues or
            questions, you may <b-link to="help">submit a help request</b-link>.
          </p>
        </b-modal>
      </div>

      <div v-if="option_number == 3" class="my-3">
        <h3>Standard Operating Procedure</h3>
        <p>Option 3 requires that you attach a standard operating procedure.</p>
        <b-form-file
          v-model="sop_file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </div>

      <b-table
        class="mt-2"
        responsive
        striped
        bordered
        :fields="table_fields"
        :items="sample_points_edited"
        primary-key="sp_id"
        :tbody-tr-class="rowClass"
      >
        <template #cell(sp_id)="row">
          <b-button
            v-if="row.item && !row.item.new_record"
            :variant="row.item.status == 'A' ? 'success' : 'danger'"
            size="sm"
            class="mr-2"
            @click="onEditNoticeClick(row.index)"
            v-b-tooltip.hover
            title="Click to Edit"
          >
            {{ row.item.sp_id }}
          </b-button>
          <b-button
            v-if="row.item && row.item.new_record"
            variant="secondary"
            size="sm"
            class="mr-2"
            @click="onEditClick(row.index)"
            v-b-tooltip.hover
            title="Click to Edit"
          >
            {{ row.item.sp_id }}
          </b-button>
          <!--<span v-if="!row.item.new_record">{{ row.item.sp_id }}</span>-->
        </template>
        <template #cell(address)="row">
          <span>
            {{ row.item.address }}
            {{ row.item.city }}
            {{ row.item.zipcode }}
          </span>
        </template>

        <template #cell(mapping)="row">
          <b-button
            :variant="determineMapButtonVariant(row.item)"
            size="sm"
            class="mr-2"
            @click="showSingleMap(row.index)"
            v-b-tooltip.hover
            title="Click to Change"
          >
            {{ determineMapStatus(row.item) }}
          </b-button>
          <b-button
            size="sm"
            v-if="row.item.latitude"
            @click="resetGeoLocation(row.index)"
          >
            Reset
          </b-button>
        </template>
      </b-table>
      <b-button variant="primary" @click="onNewSPClick"
        >+ Add New Sample Site</b-button
      >
      <b-button variant="outline-primary" @click="resetAll" class="ml-2"
        >Reset All
      </b-button>
      <div class="mt-4">
        <h4>Well List - For Reference Only</h4>
        <b-table
          :items="wells"
          responsive
          striped
          bordered
          :fields="well_fields"
        >
          <template #cell(mapping)="data">
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-2"
              @click="showSingleWellMap(data.item)"
              >View on Map</b-button
            >
          </template>
        </b-table>
      </div>
      <div>
        <b-modal
          id="sp-modal"
          title="Create Sample Point"
          ok-title="Create Sample Point"
          @ok="handleOk"
          v-if="spe"
        >
          <h4 class="mb-3">Sample Point {{ next_sample_id }}</h4>
          <b-form>
            <b-form-group
              id="street-address-fg"
              label="Street Address"
              label-for="street-address"
            >
              <b-form-input
                id="street-address"
                v-model="form_street_address"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="city-fg" label="City" label-for="city">
              <b-form-input
                id="city"
                v-model="form_city"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="zipcode-fg" label="Zip Code" label-for="zipcode">
              <b-form-input
                id="zipcode"
                type="number"
                v-model="form_zipcode"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="description-fg"
              label="Description (Optional)"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="form_description"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="note-fg" label="Note (Optional)" label-for="note">
              <b-form-input
                id="note"
                v-model="form_note"
                required
              ></b-form-input>
            </b-form-group>
          </b-form>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="sp-edit-notice-modal"
          title="Sample Point Edit Notice"
          v-if="spe"
        >
          <div v-if="spe.edited">
            <p>Remove all changes.</p>
            <b-button @click="resetOne(spe)" variant="outline-primary">
              Reset {{ spe.sp_id }} to Defaults
            </b-button>
            <hr />
          </div>
          <p>Inactivate the sample point if it is not in use.</p>
          <b-button
            v-if="!spe.new_record"
            :variant="spe.status == 'A' ? 'success' : 'danger'"
            @click="toggleStatus(edit_data_index)"
          >
            {{ this.spe.status == "A" ? "Inactivate" : "Activate" }}
            {{ spe.sp_id }}
          </b-button>
          <hr />

          <p>
            <strong>DO NOT CHANGE THE ADDRESS</strong>
            If this sample point is no longer in use, inactivate it. To create a
            new sample point at a different address, use the button below this
            list. Only click this button if the address has an error.
          </p>
          <b-button variant="warning" @click="onEditClick(edit_data_index)">
            Make Corrections (do not change address) to {{ spe.sp_id }}
          </b-button>
        </b-modal>
        <b-modal
          id="sp-edit-modal"
          title="Edit Sample Point"
          ok-title="Save Changes"
          @ok="handleEditOk"
          v-if="spe"
        >
          <h4 class="mb-3">Sample Point {{ edit_sample_id }}</h4>
          <b-button
            v-if="spe.new_record"
            variant="danger"
            class="mb-3"
            @click="onDeleteSP"
          >
            Delete {{ this.edit_sample_id }}
          </b-button>
          <b-button
            v-if="!spe.new_record"
            :variant="spe.status == 'A' ? 'success' : 'danger'"
            class="mb-3"
            @click="toggleStatus(edit_data_index)"
          >
            {{ this.spe.status == "A" ? "Inactivate" : "Activate" }}
            {{ edit_sample_id }}
          </b-button>
          <b-form>
            <b-form-group
              id="street-address-fg"
              label="Street Address"
              label-for="street-address"
            >
              <b-form-input
                id="street-address"
                v-model="form_street_address"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="city-fg" label="City" label-for="city">
              <b-form-input
                id="city"
                v-model="form_city"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="zipcode-fg" label="Zip Code" label-for="zipcode">
              <b-form-input
                id="zipcode"
                v-model="form_zipcode"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="description-fg"
              label="Description (Optional)"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="form_description"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="note-fg" label="Note (Optional)" label-for="note">
              <b-form-input id="note" v-model="form_note"></b-form-input>
            </b-form-group>
          </b-form>
        </b-modal>
      </div>

      <div id="comments-form">
        <b-modal
          id="comments-modal"
          size="lg"
          title="Sample Plan Comments (Optional)"
        >
          <p>
            Any comments you would like to include with this sample site plan.
          </p>
          <b-form-textarea v-model="form_comments" rows="6"> </b-form-textarea>
        </b-modal>
      </div>

      <div id="printable-table">
        <b-modal id="print-modal" size="xl" title="RTCR Sample Points">
          <h5>
            {{ this.pws_id }} - {{ this.pws_name }}, {{ makeDateString() }}
          </h5>
          <b-table
            striped
            bordered
            :fields="table_fields_print"
            :items="sample_points_edited"
            primary-key="sp_id"
          >
            <template #cell(address)="row">
              <span>
                {{ row.item.address }}
                {{ row.item.city }}
                {{ row.item.zipcode }}
              </span>
            </template>
          </b-table>
        </b-modal>
      </div>

      <div id="data-signing">
        <b-modal
          id="signing-modal"
          ref="signing-modal"
          @ok="handleSignModalOK"
          size="lg"
          title="Sign and Send"
        >
          <h5>
            {{ this.pws_id }} - {{ this.pws_name }}, {{ makeDateString() }}
          </h5>
          <b-form>
            <b-form-group
              label="Your Name for Signature (Required)"
              label-form="signer"
            >
              <b-form-input
                type="text"
                id="signer"
                v-model="signame"
                required
                placeholder=""
              >
              </b-form-input>
            </b-form-group>
            <p>
              Signature: &nbsp;<span class="signature">{{ signame }}</span>
            </p>
            <b-button
              :disabled="!signame || submitting_data || prevent_dup_sign"
              variant="primary"
              @click="signDoc"
              ><b-icon icon="pen"></b-icon>
              <span v-if="!submitting_data">&nbsp; Sign and Send</span>
              <span v-if="submitting_data"
                >&nbsp;
                <b-spinner label="Sending"></b-spinner>
              </span>
            </b-button>
            <p v-if="sigkey" class="mt-3">
              <b-icon icon="key"></b-icon>
              &nbsp; Using Crypto Key
              <span>{{ sigkey.key.publicKey.algorithm.name }}</span>
              &nbsp;
              <span>{{ sigkey.key.publicKey.algorithm.namedCurve }}</span>
              created on {{ sigkey.dt_created }}
            </p>
          </b-form>
          <p v-if="data_signature_ts">
            Digital Signature: {{ data_signature }}
            <br />
            {{ data_signature_ts }}
          </p>

          <b-button
            v-if="plan_receipt_id"
            variant="primary"
            :to="{ name: 'RTCRReport', params: { plan_id: plan_receipt_id } }"
            >View/Print Receipt</b-button
          >
          <b-alert class="mt-2" :show="data_submitted" variant="success">
            Data submission successful. Thank you! You're all done unless you
            want to make changes and resubmit. You should receive an email
            notification soon. Click on the button above to print a receipt.
            Transaction ID: {{ plan_receipt_id }}
          </b-alert>
          <b-alert
            :show="data_signature_ts && !data_submitted && !submitting_data"
            variant="danger"
          >
            Error submitting data. This is an unexpected error and will be
            looked into. You can try to submit the data again including any
            corrections.
          </b-alert>
          <b-alert :show="signature_ok_alert" variant="warning" fade>
            Plans not submitted yet.
          </b-alert>
        </b-modal>
      </div>

      <div>
        <b-modal
          id="gmap-modal"
          ref="gmap-modal"
          @shown="onShowMap"
          title="Routine Sample Sites"
          size="xl"
        >
          <p>TCR Sample Points and Wells</p>
          <GmapMap
            ref="spmap"
            :center="{ lat: sp_lat_avg, lng: sp_lng_avg }"
            map-type-id="roadmap"
            :options="{ gestureHandling: 'greedy' }"
            style="width: 100%; height: 60vh"
          >
            <GmapMarker
              :key="index"
              :title="m.address"
              @dragend="setPlace(index, $event)"
              v-for="(m, index) in markers"
              :label="spint(m.title)"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="center = m.position"
            />
          </GmapMap>
        </b-modal>

        <b-modal
          id="gmap-single-modal"
          ref="gmap-single-modal"
          @shown="onShowSingleMap"
          title="Choose Location"
          size="xl"
        >
          <p>Drag marker to the location of this sample point.</p>
          <GmapMap
            ref="spmap_single"
            :center="single_map_marker.position"
            :zoom="15"
            map-type-id="roadmap"
            :options="{ gestureHandling: 'greedy' }"
            style="width: 100%; height: 60vh"
          >
            <GmapMarker
              :key="single_map_index"
              :title="single_map_marker.address"
              @dragend="setPlace(single_map_index, $event)"
              :label="spint(single_map_marker.title)"
              :position="single_map_marker.position"
              :clickable="true"
              :draggable="true"
              @click="center = single_map_marker.position"
            />
          </GmapMap>
        </b-modal>

        <b-modal
          id="gmap-well-modal"
          ref="gmap-well-modal"
          @shown="onShowWellMap"
          title="Well Location"
          size="xl"
        >
          <p>Well View</p>
          <GmapMap
            ref="spmap_well"
            :zoom="15"
            :center="{ lat: well_latitude, lng: well_longitude }"
            map-type-id="roadmap"
            :options="{ gestureHandling: 'greedy' }"
            style="width: 100%; height: 60vh"
          >
            <GmapMarker
              :key="well_map_index"
              title="Well"
              :label="well_label"
              :position="{ lat: well_latitude, lng: well_longitude }"
              :clickable="false"
              :draggable="false"
            />
          </GmapMap>
        </b-modal>
        <b-modal ref="sop-modal" ok-only title="SOP Required">
          <p>You must attach your Standard Operating Procedure to proceed.</p>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Vue from "vue"
import { SessionMixin } from "../mixins"
import * as VueGoogleMaps from "vue2-google-maps"

const average = (array) => array.reduce((a, b) => a + b) / array.length

function textEncode(str) {
  if (window.TextEncoder) {
    return new TextEncoder("utf-8").encode(str)
  }
  var utf8 = unescape(encodeURIComponent(str))
  var result = new Uint8Array(utf8.length)
  for (var i = 0; i < utf8.length; i++) {
    result[i] = utf8.charCodeAt(i)
  }
  return result
}

// Base64 encode
function encode64(buff) {
  return btoa(
    new Uint8Array(buff).reduce((s, b) => s + String.fromCharCode(b), "")
  )
}

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDcZNa-cxgUyA6Hfqr772Sevv5XdbOpqFs"
    //key: "AIzaSyDxx8AnfmtH6hU7TUZiyvyY-hhwVa1V4Po",
    //libraries: "places"
  }
  //installComponents: true
})

export default {
  name: "RTCRSitePlan",
  mixins: [SessionMixin],
  props: ["option_number", "amend_plan_id"],
  data() {
    return {
      plan_option: 1,
      // ID to amend
      //amend_plan_id: null,
      // data from previous submission
      sop_file: null,
      data_signed: null,
      app_mounted: false,
      api_key: "AIzaSyDcZNa-cxgUyA6Hfqr772Sevv5XdbOpqFs",
      table_fields: [
        { key: "sp_id", label: "ID" },
        { key: "address", label: "Address" },
        { key: "description", label: "Description" },
        { key: "note", label: "Note" },
        "mapping"
      ],
      table_fields_print: [
        { key: "sp_id", label: "ID" },
        { key: "address", label: "Address" }
      ],
      wells: [],
      well_fields: [
        { key: "sp_id", label: "ID" },
        { key: "directions_text", label: "Directions" },
        "mapping"
      ],
      well_latitude: 30.3774856,
      well_longitude: -88.7553939,
      well_position: { lat: this.well_latitude, lng: this.well_longitude },
      well_label: "Well",
      well_map_index: 0,
      sample_points: [],
      // indexed by sp_id
      sample_points_sp_idx: {},
      sample_points_edited: [],
      physical_address: null,
      edit_sample_id: "TC",
      edit_data_index: 0,
      form_street_address: "",
      form_city: "",
      form_zipcode: "",
      form_description: "",
      form_note: "",
      form_comments: "",
      // maps
      google_maps_initialized: false,
      single_map_index: 0,
      //sp_lat: 32.32839415942409,
      //sp_lng: -90.17660048704145,
      sp_lat_sel: null,
      sp_lng_sel: null,
      sp_lat_avg: 0,
      sp_lng_avg: 0,
      // signature
      sigkey: null,
      signame: null,
      data_signature: null,
      data_signature_ts: null,
      data_submitted: false,
      submitting_data: false,
      // alert when OK is pressed before signed
      signature_ok_alert: false,
      prevent_dup_sign: false,
      plan_receipt_id: null
    }
  },
  async mounted() {
    console.log("mounted")
    this.sigkey = await this.getSigKey()
    console.log("sigkey: " + this.sigkey)
  },
  //mounted() {
  //  console.log(this.$refs.spmap)
  //  this.$refs.spmap.$mapPromise.then(() => {
  //    this.google_maps_initialized = true
  //  })
  //},
  async created() {
    console.log("RTCRSitePlan Created")
    await this.getPhysicalAddress()
    await this.fetchSamplePoints()
    console.log("option_number", this.option_number)
    console.log("amend_plan_id", this.amend_plan_id)
    // Read if option if <= 3.  Otherwise, read as :b
    // maybe a better way to handle this
    //this.amend_plan_id = this.$route.params.plan_id
    //console.log('amend plan id: ' + this.amend_plan_id)
    if (this.amend_plan_id) {
      await this.fetchSamplePlan(this.amend_plan_id)
    }
    await this.fetchWells()
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.onSubmit()
    },
    handleEditOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.onEditSubmit()
    },
    onShowSigningModal() {
      if (this.option_number == 3 && !this.sop_file) {
        this.$refs["sop-modal"].show()
      } else {
        this.$refs["signing-modal"].show()
      }
    },
    handleSignModalOK(bvModalEvt) {
      if (!this.data_submitted) {
        this.signature_ok_alert = true
        bvModalEvt.preventDefault()
        // reset dup sig check
        this.prevent_dup_sign = false
      } else {
        this.$router.push("/rtcr")
      }
    },
    resetOne(edit_rset) {
      let sp = this.sample_points_sp_idx[edit_rset.sp_id]
      Object.assign(edit_rset, sp)
    },
    resetAll() {
      this.sample_points_edited = []
      this.sample_points.forEach((e) => {
        let edit_rset = {}
        edit_rset = Object.assign(edit_rset, e)
        this.sample_points_edited.push(edit_rset)
      })
    },
    async geocodeAddress(rset) {
      const path = process.env.VUE_APP_API_URI + "/address-geocode"
      const addr = rset.address + ", " + rset.city + ", MS " + rset.zipcode
      console.log("geocode: " + addr)
      try {
        let response = await this.axios.get(path, {
          params: { qry_address: addr }
        })
        console.log(response)
        if (response.data) {
          console.log("setting lat lon from query")
          console.log(rset)
          rset.geo_latitude = response.data.geocode.latitude
          rset.geo_longitude = response.data.geocode.longitude
          rset.latitude = null
          rset.longitutde = null
        }
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmit() {
      console.log("adding new record")
      let new_rset = {
        sp_id: this.next_sample_id,
        address: this.form_street_address,
        city: this.form_city,
        zipcode: this.form_zipcode,
        description: this.form_description,
        note: this.form_note,
        status: "N",
        edited: false,
        new_record: true,
        latitude: null,
        longitude: null,
        geo_latitude: null,
        geo_longitude: null
      }
      await this.geocodeAddress(new_rset)
      this.sample_points_edited.push(new_rset)
      this.$nextTick(() => {
        this.$bvModal.hide("sp-modal")
      })
    },
    async onEditSubmit() {
      console.log("editing record")
      let address_change = false
      let rset = this.sample_points_edited[this.edit_data_index]
      if (
        rset.address !== this.form_street_address ||
        rset.zipcode !== this.form_zipcode
      ) {
        address_change = true
      }
      rset.address = this.form_street_address
      rset.city = this.form_city
      rset.zipcode = this.form_zipcode
      rset.description = this.form_description
      rset.note = this.form_note
      if (!rset.new_record) {
        rset.edited = true
      }
      if (address_change) {
        console.log("address change - geocoding")
        await this.geocodeAddress(rset)
      }
      this.$nextTick(() => {
        this.$bvModal.hide("sp-edit-modal")
      })
    },
    onDeleteSP() {
      this.sample_points_edited.splice(this.edit_data_index, 1)
      this.$nextTick(() => {
        this.$bvModal.hide("sp-edit-modal")
      })
    },
    onEditNoticeClick(data_index) {
      console.log(data_index)
      this.edit_data_index = data_index
      this.$nextTick(() => {
        this.$bvModal.show("sp-edit-notice-modal")
      })
    },
    onNewSPClick() {
      this.form_street_address = ""
      this.form_city = ""
      this.form_zipcode = ""
      this.form_note = ""
      this.form_description = ""
      this.$bvModal.show("sp-modal")
    },
    onEditClick(data_index) {
      console.log(data_index)
      this.edit_data_index = data_index
      let rset = this.sample_points_edited[data_index]
      this.edit_sample_id = rset["sp_id"]
      this.form_street_address = rset["address"]
      this.form_city = rset["city"]
      this.form_zipcode = rset["zipcode"]
      this.form_note = rset["note"]
      this.form_description = rset["description"]
      this.$bvModal.hide("sp-edit-notice-modal")
      this.$bvModal.show("sp-edit-modal")
    },
    resetGeoLocation(data_index) {
      let rset = this.sample_points_edited[data_index]
      rset.latitude = null
      rset.longitude = null
    },
    rowClass(item, type) {
      if (!item || type !== "row") return
      if (item.edited) return "table-warning"
    },
    async getPhysicalAddress() {
      const path = process.env.VUE_APP_API_URI + "/pws-physical-address"
      try {
        let response = await this.axios.get(path, {
          params: { pws_id: this.pws_id }
        })
        console.log("physical address")
        console.log(response)
        if (response.data) {
          this.physical_address = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchWells() {
      const path = process.env.VUE_APP_API_URI + "/ss-tcr-wells"
      try {
        let response = await this.axios.get(path, {
          params: { pws_id: this.pws_id }
        })
        if (response.data) {
          this.wells = response.data.wells
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSamplePoints() {
      const path = process.env.VUE_APP_API_URI + "/ss-tcr-sample-points"
      try {
        let response = await this.axios.get(path, {
          params: { pws_id: this.pws_id }
        })
        //let sample_point_data = []
        if (response.data) {
          let city = ""
          let zipcode = ""
          // calculate average of all lats and lons
          //let lats = []
          //let lons = []
          for (const rset of response.data) {
            let sp_id = rset.sp_id
            let note_1 = rset.note_1
            let note_2 = rset.note_2
            let note_3 = rset.note_3
            let description_text = rset.description_text
            let status = rset.status
            let geo_latitude = rset.geo_latitude
            let geo_longitude = rset.geo_longitude
            let latitude = rset.latitude
            let longitude = rset.longitude
            city = note_2
            zipcode = ""
            // try to hash out city and zip
            let note_2_array = note_2.split(",")
            if (note_2_array.length == 2) {
              city = note_2_array[0].trim()
              zipcode = note_2_array[1].trim()
              // if MS is prepended to zipcode, remove it
              if (zipcode.substring(0, 2) == "MS") {
                zipcode = zipcode.substring(2).trim()
              }
              //console.log("yes.............." + zipcode)
            }
            let new_rset = {
              sp_id: sp_id,
              address: note_1,
              city: city,
              zipcode: zipcode,
              note: note_3,
              description: description_text,
              status: status,
              longitude: longitude,
              latitude: latitude,
              title: sp_id,
              geo_longitude: geo_longitude,
              geo_latitude: geo_latitude,
              edited: false,
              new_record: false
            }
            //lats.push(geo_latitude)
            //lons.push(geo_longitude)
            //if (geo_latitude) {
            //  console.log(geo_latitude)
            //  this.markers.push({
            //    position: { lat: geo_latitude, lng: geo_longitude },
            //    title: sp_id, address: note_1
            //  })
            //}
            let edit_rset = {}
            edit_rset = Object.assign(edit_rset, new_rset)
            this.sample_points.push(new_rset)
            this.sample_points_sp_idx[sp_id] = new_rset
            this.sample_points_edited.push(edit_rset)
          }
          // set map center by calculating averages
          //this.sp_lat = average(lats)
          //this.sp_lng = average(lons)
          //console.log(average(lats))
          //this.sample_points = sample_point_data
          this.form_city = city
          if (!isNaN(zipcode)) {
            this.form_zipcode = zipcode
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSamplePlan(plan_id) {
      console.log("fetching data for amend")
      const path = process.env.VUE_APP_API_URI + "/rtcr-plan"
      try {
        let response = await this.axios.get(path, {
          params: { plan_id: plan_id }
        })
        console.log(response)
        if (response.data.sample_plan) {
          let sp = response.data.sample_plan
          let data_signed = JSON.parse(sp.data_signed)
          this.data_signed = data_signed
          this.option_number = data_signed.plan_type_code
          this.form_comments = data_signed.comments
          //console.log(data_signed)
          data_signed.sample_points.forEach((sp) => {
            // find and replace in edit array
            if (sp.edited) {
              const idx = this.sample_points_edited.find(
                (e) => e.sp_id == sp.sp_id
              )
              console.log("looking for index to replace")
              console.log(idx)
              if (idx !== undefined) {
                console.log("replacing")
                Object.assign(idx, sp)
                //this.sample_points_edited[idx] = sp
              }
            }
            if (sp.new_record) {
              console.log("New")
              this.sample_points_edited.push(sp)
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    showMap() {
      this.calcCenter()
      this.$refs["gmap-modal"].show()
      //console.log(this.$refs.spmap)
      //this.$refs.["spmap"].$mapPromise.then(() => {
      //  this.google_maps_initialized = true
      //  this.fitBounds()
      //})
    },
    onShowMap() {
      this.$refs.spmap.$mapPromise.then(() => {
        this.google_maps_initialized = true
        this.fitBounds(this.$refs.spmap)
      })
    },
    showSingleMap(sp_index) {
      console.log(sp_index)
      this.single_map_index = sp_index
      this.calcCenter()
      this.$refs["gmap-single-modal"].show()
    },
    onShowSingleMap() {
      this.$refs.spmap_single.$mapPromise.then(() => {
        // pass
        console.log("showing single map")
        //this.fitBounds(this.$refs.spmap_single)
      })
    },
    showSingleWellMap(item) {
      this.well_latitude = item.latitude_measure
      this.well_longitude = item.longitude_measure
      this.well_label = item.sp_id
      console.log(item)
      this.$refs["gmap-well-modal"].show()
    },
    onShowWellMap() {
      this.$refs.spmap_well.$mapPromise.then(() => {
        //pass
        console.log("showing well map")
        //let b = new window.google.maps.LatLngBounds()
        //b.extend({lat: this.well_latitude, lng: this.well_longitude})
        //this.$refs.spmap_well.fitBounds(b)
        //this.fitBounds(this.$refs.spmap_well)
      })
    },
    onShow() {
      this.$refs.spmap_single.$mapPromise.then(() => {
        this.fitBounds(this.$refs.spmap_single)
      })
    },
    fitBounds(map) {
      console.log("fitting bounds")
      //if (!this.google_maps_initialized) return null
      let b = new window.google.maps.LatLngBounds()
      //console.log(b)
      this.markers.forEach((marker) => {
        b.extend(marker.position)
      })
      // Use Physical Address if 0 markers
      if (this.markers.length == 0) {
        b.extend(this.single_map_marker.position)
      }
      map.fitBounds(b)
    },
    calcCenter() {
      // calc center
      let lats = []
      let lons = []
      let lat_avg = 0
      let lon_avg = 0
      this.sample_points_edited.forEach((e) => {
        if (e.latitude) {
          lats.push(e.latitude)
          lons.push(e.longitude)
        } else if (e.geo_latitude) {
          lats.push(e.geo_latitude)
          lons.push(e.geo_longitude)
        }
      })
      if (lats.length > 0) {
        lat_avg = average(lats)
        lon_avg = average(lons)
      } else if (this.physical_address.geoloc) {
        console.log("setting avg to physical address")
        lat_avg = this.physical_address.geoloc.latitude
        lon_avg = this.physical_address.geoloc.longitude
      }
      console.log("lat avg: " + lat_avg)
      console.log("lon avg: " + lon_avg)
      this.sp_lat_avg = lat_avg
      this.sp_lng_avg = lon_avg
    },
    toggleStatus(index) {
      console.log("toggle status " + index)
      let record = this.sample_points_edited[index]
      console.log("edit: " + record.status)
      console.log("fresh: " + this.sample_points[index].status)
      console.log(record.status)
      record.status = record.status == "A" ? "I" : "A"
      if (record.status != this.sample_points[index].status) {
        record.edited = true
      } else {
        record.edited = false
      }
    },
    setPlace(index, place) {
      console.log(index)
      console.log(place)
      this.sp_lat_sel = place.latLng.lat()
      //this.sp_lat = this.sp_lat
      this.sp_lng_sel = place.latLng.lng()
      //this.sp_lng = this.sp_lng
      let rset = this.sample_points_edited[index]
      rset.latitude = place.latLng.lat()
      rset.longitude = place.latLng.lng()
      rset.edited = true
      //this.sample_points_edited[index].latitude = place.latLng.lat()
      //this.sample_points_edited[index].longitude = place.latLng.lng()
      //this.sample_points_edited[index].edited = true
    },
    geocodeAll() {
      this.sample_points_edited.forEach(async (sp) => {
        await this.geocodeAddress(sp)
      })
    },
    spint(sp) {
      if (!sp) {
        return ""
      }
      if (sp.startsWith("WL")) {
        return sp
      }
      let t = sp.substr(2, 10)
      return parseInt(t).toString()
    },
    determineMapStatus(sp_obj) {
      //console.log("Determine map status")
      //console.log(sp_obj)
      if (sp_obj.latitude) {
        return "Point Chosen"
      } else if (sp_obj.geo_latitude) {
        return "Mapped From Address"
      } else {
        return "Not Set. Click to Choose."
      }
    },
    determineMapButtonVariant(sp_obj) {
      //console.log("Determine map status")
      //console.log(sp_obj)
      if (sp_obj.latitude) {
        return "primary"
      } else if (sp_obj.geo_latitude) {
        return "primary"
      } else {
        return "outline-danger"
      }
    },
    makeDateString() {
      let today = new Date()
      const dtstr = today.toLocaleDateString("en-US")
      return dtstr
    },
    async signDoc() {
      this.submitting_data = true
      this.signature_ok_alert = false
      try {
        const data_signature_ts = new Date().toISOString()
        const submit_data = {
          plan_type_code: this.option_number.toString(),
          sample_points: this.sample_points_edited,
          wells: this.wells,
          data_signature_ts: data_signature_ts,
          signame: this.signame,
          pws_id: this.pws_id,
          comments: this.form_comments
        }
        const serialized_data = JSON.stringify(submit_data)
        //console.log(serialized_data)
        // byte array for signing
        // const encoded_message = new TextEncoder().encode(serialized_data)
        const encoded_message = textEncode(serialized_data)
        // sign also hashes data - no need for seperate hash
        const signature = await window.crypto.subtle.sign(
          { name: "ECDSA", hash: { name: "SHA-256" } },
          this.sigkey.key.privateKey,
          encoded_message
        )
        this.signed_data = serialized_data
        this.data_signature = encode64(signature)
        this.data_signature_ts = data_signature_ts
        await this.submitData()
      } catch (err) {
        console.log(err)
      } finally {
        this.submitting_data = false
      }
    },
    async exportPublicKey() {
      // export the b64 public key
      const key = this.sigkey.key
      const bkey = window.crypto.subtle.exportKey("spki", key.publicKey)
      const b64key = encode64(bkey)
      const pem_key = `-----BEGIN PUBLIC KEY-----\n${b64key}\n-----END PUBLIC KEY-----`
      console.log("public key b64: " + pem_key)
      return pem_key
    },
    async submitData() {
      const path = process.env.VUE_APP_API_URI + "/submit-rtcr-plan"
      const public_key = await this.exportPublicKey()
      const params = {
        amend_plan_id: this.amend_plan_id,
        signed_data: this.signed_data,
        signature: this.data_signature,
        signature_pk: public_key
      }
      console.log(params)
      try {
        let response = null
        if (this.option_number == 3) {
          //params.sop_file = this.sop_file
          console.log(this.sop_file)
          let form_data = new FormData()
          form_data.append('sop_file', this.sop_file)
          form_data.append('site_plan_data', JSON.stringify(params))
          response = await this.axios.post(
            path, form_data, { headers: {"Content-Type": "multipart/form-data"} }
          )
        } else {
          response = await this.axios.post(path, params)
        }
        console.log(response)
        if (response.data) {
          console.log("Site plan posted.")
          this.plan_receipt_id = response.data.plan_id
          this.data_submitted = true
          // prevent dup sig
          this.prevent_dup_sign = true
        }
      } catch (error) {
        this.data_submitted = false
        console.log(error)
      }
    }
  },
  computed: {
    //pws_id: function() {
    //  return this.$store.state.pws.pws_id
    //},
    //pws_name: function() {
    //  return this.$store.state.pws.pws_name
    //},
    // current sample point for easy ref
    spe: function() {
      return this.sample_points_edited[this.edit_data_index]
    },
    // average lat
    markers: function() {
      console.log("computing markers")
      let m = []
      this.sample_points_edited.forEach((e) => {
        if (e.latitude && e.status == "A") {
          m.push({
            position: { lat: e.latitude, lng: e.longitude },
            title: e.sp_id,
            address: "Point"
          })
        } else if (e.geo_latitude && e.status == "A") {
          m.push({
            position: { lat: e.geo_latitude, lng: e.geo_longitude },
            title: e.sp_id,
            address: e.address
          })
        }
      })
      // Add the wells
      this.wells.forEach((w) => {
        m.push({
          position: { lat: w.latitude_measure, lng: w.longitude_measure },
          title: w.sp_id,
          address: w.directions_text
        })
      })
      return m
    },
    single_map_marker: function() {
      let e = this.sample_points_edited[this.single_map_index]
      let marker = {}
      if (!e) {
        return marker
      }
      if (e.latitude) {
        marker.position = { lat: e.latitude, lng: e.longitude }
        marker.title = e.sp_id
        marker.address = "Point"
      } else if (e.geo_latitude) {
        marker.position = { lat: e.geo_latitude, lng: e.geo_longitude }
        marker.title = e.sp_id
        marker.address = e.address
      } else if (this.physical_address && this.physical_address.geoloc) {
        console.log("using geoloc from physical address")
        marker.position = {
          lat: this.physical_address.geoloc.latitude,
          lng: this.physical_address.geoloc.longitude
        }
        marker.title = e.sp_id
        marker.address = this.physical_address.address
      }
      console.log("Single Marker")
      //console.log(marker)
      return marker
    },
    all_sp_mapped: function() {
      console.log("computing all_sp_mapped")
      let all_mapped = true
      // don't count inactive as empty
      let empty_points = this.sample_points_edited.filter(
        (sp) => !(sp.latitude || sp.geo_longitude) && sp.status == "A"
      )
      console.log("empty points")
      console.log(empty_points)
      if (empty_points.length > 0) {
        all_mapped = false
      }
      console.log("all_mapped: " + all_mapped)
      return all_mapped
    },
    next_sample_id: function() {
      let d = this.sample_points_edited
      if (d.length > 0) {
        let sp_id = d[d.length - 1].sp_id
        console.log(sp_id)
        let sp_no = parseInt(sp_id.substring(2, 6)) + 1
        console.log(sp_no)
        return "TC" + sp_no.toString().padStart(4, "0")
      } else {
        return "TC"
      }
    }
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Indie+Flower&display=swap");
.form-group.required > label:after {
  content: " *";
  color: red;
}

.signature {
  font-family: "Dancing Script", cursive;
  font-size: 2em;
}
</style>
