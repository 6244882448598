<template>
  <div class="rtcr-site-plan">
    <h4 class="mt-4 mb-2">
      Routine TCR Sample Sites, {{ this.pws_id }} - {{ this.pws_name }}
    </h4>

    <p class="mt-2 mb-4">
      <br />
      This is a list of RTCR sample points currently in use by the Mississippi State Department of Health, Bureau of Public Water Supply as of {{ makeDateString() }}. It may not reflect the most recently submitted plans if they have not yet been approved and imported.
      <br />
      <div class="no-print">
      <b-button variant="primary" onclick="window.print()">
        <b-icon icon="printer"></b-icon>
        Print
      </b-button>
      <br />
      <br />
    </div>
    <div>

      <b-table
        class="mt-2"
        responsive
        striped
        bordered
        :fields="table_fields"
        :items="sample_points"
        primary-key="sp_id"
        :tbody-tr-class="rowClass"
      >
        <template #cell(address)="row">
          <span>
            {{ row.item.address }}
            {{ row.item.city }}
            {{ row.item.zipcode }}
          </span>
        </template>

      </b-table>

      <div id="printable-table">
        <b-modal id="print-modal" size="xl" title="RTCR Sample Points">
          <h5>
            {{ this.pws_id }} - {{ this.pws_name }}, {{ makeDateString() }}
          </h5>
          <b-table
            striped
            bordered
            :fields="table_fields_print"
            :items="sample_points"
            primary-key="sp_id"
          >
            <template #cell(address)="row">
              <span>
                {{ row.item.address }}
                {{ row.item.city }}
                {{ row.item.zipcode }}
              </span>
            </template>
          </b-table>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { SessionMixin } from "../mixins"


export default {
  name: "RTCRSiteList",
  mixins: [SessionMixin],
  data() {
    return {
      plan_option: 1,
      // ID to amend
      //amend_plan_id: null,
      // data from previous submission
      sop_file: null,
      data_signed: null,
      app_mounted: false,
      table_fields: [
        { key: "sp_id", label: "ID" },
        { key: "address", label: "Address" },
        { key: "description", label: "Description" },
        { key: "note", label: "Note" },
      ],
      table_fields_print: [
        { key: "sp_id", label: "ID" },
        { key: "address", label: "Address" }
      ],
      sample_points: [],
      // indexed by sp_id
      sample_points_sp_idx: {},
    }
  },
  async mounted() {
    console.log("mounted")
  },

  async created() {
    console.log("RTCRSiteList Created")
    await this.fetchSamplePoints()
  },

  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return
      if (item.edited) return "table-warning"
    },
    async fetchSamplePoints() {
      const path = process.env.VUE_APP_API_URI + "/ss-tcr-sample-points"
      try {
        let response = await this.axios.get(path, {
          params: { pws_id: this.pws_id }
        })
        //let sample_point_data = []
        if (response.data) {
          let city = ""
          let zipcode = ""
          // calculate average of all lats and lons
          //let lats = []
          //let lons = []
          for (const rset of response.data) {
            let sp_id = rset.sp_id
            let note_1 = rset.note_1
            let note_2 = rset.note_2
            let note_3 = rset.note_3
            let description_text = rset.description_text
            let status = rset.status
            let geo_latitude = rset.geo_latitude
            let geo_longitude = rset.geo_longitude
            let latitude = rset.latitude
            let longitude = rset.longitude
            city = note_2
            zipcode = ""
            // try to hash out city and zip
            let note_2_array = note_2.split(",")
            if (note_2_array.length == 2) {
              city = note_2_array[0].trim()
              zipcode = note_2_array[1].trim()
              //console.log("yes.............." + zipcode)
            }
            let new_rset = {
              sp_id: sp_id,
              address: note_1,
              city: city,
              zipcode: zipcode,
              note: note_3,
              description: description_text,
              status: status,
              longitude: longitude,
              latitude: latitude,
              title: sp_id,
              geo_longitude: geo_longitude,
              geo_latitude: geo_latitude,
              is_primary: true,
            }
            if (rset.status == "A") {
              this.sample_points.push(new_rset)
              this.sample_points_sp_idx[sp_id] = new_rset
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    makeDateString() {
      let today = new Date()
      const dtstr = today.toLocaleDateString("en-US")
      return dtstr
    },
  },
}
</script>
<style>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .pagebreak { page-break-before: always; }
}
</style>
